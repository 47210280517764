<script setup>
const props = defineProps({
  data: String,
  isEvent: {
    type: Boolean,
    default: false,
  },
});

const formatDateString = computed(() => {
  // Create a new Date object from the input string
  const date = new Date(props.data);

  if (isNaN(date)) {
    throw new Error("Invalid date string");
  }

  // Define options for date formatting
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Format the date
  return date.toLocaleDateString("en-GB", options);
});

const formatted = computed(() =>
  !props.data
    ? ""
    : new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(Date.parse(props.data.slice(0, -3)))
);
</script>

<template>
  <!-- <p class="date" v-if="data">{{ data }}</p> -->
  <p class="date" v-if="data" v-html="isEvent ? formatDateString : formatted" />
</template>

<style lang="postcss"></style>
